<template>
    <div class="min-h">
        <!--        GetQuoteModal-->
        <transition name="fade" mode="out-in">
            <div v-if="loading">
                <custom-loading-animation></custom-loading-animation>
            </div>
            <div v-else-if="state === 'S'" key="'S'" class="fl-x fl-a-c fl-j-c">
                <div class="text-center fl-y fl-a-c fl-j-c">
                    <success-icon class="mb-2" variant="info"/>
                    <p class="m-0">Thank you <strong>{{ name }}</strong> for Contacting us
                    </p>
                    We have received your request and will respond to you within 24 hours.
                </div>
            </div>
            <div v-else-if="state === 'F'">
                <div class="text-danger">
                    <div class="text-center mb-4">
                        <!--                        <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>-->
                        <error-icon></error-icon>
                    </div>
                    <div class="text-center">
                        <p class="fs-4"><strong>OOPS!</strong></p>
                        Something has gone wrong, Please try again.
                    </div>
                </div>
            </div>
            <div v-else key="'FORM'">
                <s-form class="row m-0" role="form" @submit="onSubmit"
                        autocomplete="off">
                    <div class="col-12">
                        <validated-input size="sm" name="Name" v-model="model.name" shadow="0" placeholder="NAME"
                                         :rules="rules.first_name" required/>
                        <validated-input size="sm" type="email" name="Email" v-model="model.email" shadow="0"
                                         placeholder="EMAIL"
                                         :rules="rules.email_id"/>
                        <validated-input size="sm" type="number" name="Phone" v-model="model.phone" shadow="0"
                                         placeholder="PHONE"
                                         :rules="rules.phone"/>
                        <validated-text-area size="sm" placeholder="MESSAGE" v-model="model.message"
                                             :rules="rules.message1"
                                             shadow="0"/>
                    </div>
                    <div class="col-12 fl-x fl-j-c">
                        <button class="lego-btn btn-sm w-50 bg-primary round-1 px-5 text-bold" type="submit">
                            Submit
                        </button>
                    </div>
                </s-form>
            </div>
        </transition>

    </div>
</template>

<script>
import urls from '../data/urls';

import axios from 'secure-axios';
import CustomLoadingAnimation from '@components/CustomLoadingAnimation';

export default {
    name: 'GetQuoteModal',
    components: { CustomLoadingAnimation },
    data () {
        return {
            loading: false,
            addUrl: urls.getquote.add,
            state: 'D',
            name: '',
            email: '',
            phone: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                phone: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            },
            model: {}
        };
    },
    methods: {
        async onSubmit (action) {
            const that = this;
            that.loading = true;
            const response = await axios.form(that.addUrl, that.model);
            const json = response.data;
            if (json.error === false) {
                // that.formSuccess(response);
                that.$emit('success');
                that.state = 'S';
                that.loading = false;
            } else {
                that.formError(json);
                that.state = 'F';
                that.loading = false;
            }
            that.loading = false;
        },
        openAddModal () {
            this.$refs.GetAQuote.show();
        }
    }
};
</script>

<style scoped>

</style>
